import { FormattedDate, FormattedList } from "react-intl";
import {
  Input,
  Measurement,
  MeasurementsListItem,
  defaultFilters,
  filterEquals,
  useMeasurements,
} from "api/measurements";
import Map, { MapToggle } from "./Map";
import Pagination, { MobilePagination } from "./Pagination";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AccountContext } from "templates/Dashboard";
import { CalibrationText } from "lib/calibrations";
import { ComprehensivenessIcon } from "./ComprehensivenessIndicator";
import Filter from "./Filter";
import ListActions from "./ListActions";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import Table from "./Table";
import { ThemeContext } from "index";
import { classes } from "lib/helpers";
import errorImage from "images/error.svg";
import styles from "./List.module.scss";
import useMediaQuery from "../../lib/useMediaQuery";

function ComprehensivenessExplanation() {
  return (
    <div className={styles.comprehensivenessExplanation}>
      <ComprehensivenessIcon />{" "}
      <span>
        <T id="measurement.comprehensivenessIndicator" />
      </span>
    </div>
  );
}

function List({
  input,
  onInput,
}: {
  input: Input;
  onInput: (input: Input) => void;
}) {
  const [mapOpen, setMapOpen] = useState(false);
  const [resetToBounds, setResetToBounds] = useState(true);
  const [output, loading] = useMeasurements(input);
  const [checked, setChecked] = useState<MeasurementsListItem[]>([]);
  const [hovered, setHovered] = useState<
    MeasurementsListItem | Measurement | null
  >(null);
  const [filteredMeasurements, setFilteredMeasurements] = useState<
    Measurement[]
  >([]);

  const matchesMaxWidth1550 = useMediaQuery("(max-width: 1550px)");
  const matchesMaxWidth370 = useMediaQuery("(max-width: 370px)");

  const FilterDescription = () => {
    return (
      <div className={styles.filterDescription}>
        <span>
          <T id="measurements.filters.description.showing" />{" "}
          <strong>
            {input.calibration__uuid__in.length ? (
              <FormattedList
                type="conjunction"
                value={input.calibration__uuid__in.map((c) => (
                  <CalibrationText key={c} calibration={c} />
                ))}
              />
            ) : (
              <T id="measurements.filters.description.allSpecies" />
            )}
          </strong>
          {input.measured_at__gte ? (
            <>
              {" "}
              <T id="measurements.filters.description.from" />{" "}
              <strong>
                <FormattedDate value={input.measured_at__gte} />
              </strong>
            </>
          ) : null}
          {input.measured_at__lte ? (
            <>
              {" "}
              <T id="measurements.filters.description.to" />{" "}
              <strong>
                <FormattedDate value={input.measured_at__lte} />
              </strong>
            </>
          ) : null}
        </span>

        {showResetFilters ? (
          <button
            className="cta small"
            onClick={() => onInput({ ...input, ...defaultFilters })}
          >
            <T id="measurements.filters.reset" />
          </button>
        ) : null}
      </div>
    );
  };

  const Controls = () => {
    return (
      <div className={classes("dashboardTop", styles.controls)}>
        <div className={styles.right}>
          <div className={styles.actionGroup}>
            <Filter
              filters={input}
              onFilters={(filters) => onInput({ ...input, ...filters })}
            />
            <ListActions
              measurements={checked}
              onDelete={() => onInput({ ...input }) /* Force API call */}
              onGroup={() => onInput({ ...input })}
            />
          </div>
          <FilterDescription />
        </div>
        <div className={styles.left}>
          <MapToggle open={mapOpen} onOpen={setMapOpen} />
          <Pagination
            count={output!.count}
            pagination={input}
            onPagination={(pagination) => onInput({ ...input, ...pagination })}
          />
        </div>
        {account?.is_vad ? (
          <div className={styles.vadWarning}>
            <img src={errorImage} alt="error" />
            <T
              id={
                theme && theme.name === "GS"
                  ? "measurements.vadWarning"
                  : "measurements.vadWarning.others"
              }
            />
          </div>
        ) : null}
      </div>
    );
  };

  const ControlsMobile = () => {
    return (
      <div className={classes("dashboardTop", styles.mobileControls)}>
        <div>
          <Filter
            filters={input}
            onFilters={(filters) => onInput({ ...input, ...filters })}
          />{" "}
          <ListActions
            measurements={checked}
            onDelete={() => onInput({ ...input }) /* Force API call */}
            onGroup={() => onInput({ ...input })}
          />
        </div>
        <div className={styles.gridItemRight}>
          <MapToggle open={mapOpen} onOpen={setMapOpen} />
        </div>

        <div
          className={classes(
            styles.filterDescription,
            styles.filterDescriptionMarginTop
          )}
        >
          <T id="measurements.filters.description.showing" />{" "}
          <strong>
            {input.calibration__uuid__in.length ? (
              <FormattedList
                type="conjunction"
                value={input.calibration__uuid__in.map((c) => (
                  <CalibrationText key={c} calibration={c} />
                ))}
              />
            ) : (
              <T id="measurements.filters.description.allSpecies" />
            )}
          </strong>
          {input.measured_at__gte ? (
            <>
              {" "}
              <T id="measurements.filters.description.from" />{" "}
              <strong>
                <FormattedDate value={input.measured_at__gte} />
              </strong>
            </>
          ) : null}
          {input.measured_at__lte ? (
            <>
              {" "}
              <T id="measurements.filters.description.to" />{" "}
              <strong>
                <FormattedDate value={input.measured_at__lte} />
              </strong>
            </>
          ) : null}
        </div>

        <div
          className={classes(
            styles.gridItemRight,
            styles.filterDescriptionMarginTop
          )}
        >
          {showResetFilters ? (
            <button
              className={classes("cta small", styles.resetButton)}
              onClick={() => onInput({ ...input, ...defaultFilters })}
            >
              <T id="measurements.filters.reset" />
            </button>
          ) : null}
        </div>

        <div
          className={classes(
            styles.colspan2,
            matchesMaxWidth370 ? null : styles.gridItemRight
          )}
        >
          <MobilePagination
            count={output!.count}
            pagination={input}
            onPagination={(pagination) => onInput({ ...input, ...pagination })}
          />
        </div>
      </div>
    );
  };

  const showResetFilters = useMemo(
    () => !filterEquals(input, defaultFilters),
    [input]
  );
  const account = useContext(AccountContext);
  const theme = useContext(ThemeContext);

  useEffect(() => setChecked([]), [input]);

  const toggleMeasurement = useCallback(
    (m) =>
      setChecked((checked) =>
        checked.includes(m) ? checked.filter((x) => x !== m) : checked.concat(m)
      ),
    []
  );

  useEffect(() => {
    setResetToBounds(true);

    if (output) {
      setFilteredMeasurements(
        output.results.flatMap((item: MeasurementsListItem) => {
          return item.isGroup ? item.measurements : [item];
        })
      );
    }
  }, [output]);

  if (!output || !account) {
    return <Spinner />;
  }

  return (
    <>
      <h1>
        <T id="measurements" />
      </h1>
      <div className={classes(styles.root, loading && styles.loading)}>
        {matchesMaxWidth1550 ? <ControlsMobile /> : <Controls />}
        <div className={styles.main}>
          <div className={styles.tableContainer}>
            <ComprehensivenessExplanation />
            <Table
              measurements={output.results}
              ordering={input.ordering}
              onOrdering={(ordering) => onInput({ ...input, ordering })}
              checked={checked}
              hovered={hovered}
              onChecked={setChecked}
            />
          </div>
          <div
            className={classes("mapBackground", mapOpen && "open")}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setMapOpen(false);
              }
            }}
          >
            <div className="map">
              <Map
                measurements={filteredMeasurements}
                onHovered={setHovered}
                onClick={toggleMeasurement}
                resetToBounds={resetToBounds}
                setResetToBounds={setResetToBounds}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
