import React from "react";
import styles from "./FillCheckbox.module.scss";

interface FillCheckboxProps {
  checked: boolean;
  onChecked: (checked: boolean) => void;
  children: React.ReactNode;
}

const FillCheckbox: React.FC<FillCheckboxProps> = ({
  checked,
  onChecked,
  children,
}) => {
  return (
    <label className={styles.checkboxLabel}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChecked(e.target.checked)}
        className={styles.hiddenCheckbox}
      />
      <span
        className={`${styles.customCheckbox} ${checked ? styles.checked : ""}`}
      >
        {children}
      </span>
    </label>
  );
};

export default FillCheckbox;
