import { FormattedMessage, IntlProvider } from "react-intl";
import React, { ReactNode, useContext, useMemo } from "react";
import { defaultLocale, messages } from "config";
import { Locale } from "config";
import { ThemeContext } from "index";
import { useSession } from "./session";

export function LanguageProvider({ children }: { children?: ReactNode }) {
  const { locale } = useSession();
  const theme = useContext(ThemeContext);

  const currentMessages = useMemo(() => {
    return Object.fromEntries(
      Object.keys(messages[defaultLocale]).map((key) => {
        let l: Locale = locale as Locale;
        if (
          theme &&
          theme.language &&
          theme.language.available &&
          theme.language.default &&
          theme.language.available.length === 1
        ) {
          l = theme.language.default as Locale;
        }
        const message = (messages[l] as Record<string, string | undefined>)[
          key
        ];
        if (message === "" || !message) {
          return [
            key,
            (messages[defaultLocale] as Record<string, string>)[key],
          ];
        } else {
          return [key, message];
        }
      })
    );
  }, [locale, theme]);

  return (
    <IntlProvider
      messages={currentMessages}
      locale={locale}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  );
}

export const T = FormattedMessage;
