import { Locale, defaultLocale, messages } from "config";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Session, loginRefresh } from "api/login";
import { clearCookie, getCookie, setCookie } from "./helpers";
import Spinner from "components/Spinner";

const startCookie = getCookie("token");

export function parseLocale(locale: string): Locale {
  function isValidLocale(locale: string): locale is Locale {
    return locale in messages;
  }
  if (isValidLocale(locale)) {
    return locale;
  }
  const short = locale.substring(0, 2);
  if (isValidLocale(short)) {
    return short;
  }
  return defaultLocale;
}

let startLocale = parseLocale(navigator.language);

const emptyState = {
  token: null as string | null,
  email: "",
  name: "",
  locale: startLocale,
  handheld_iba_enabled: true,
};

const Context = createContext({
  ...emptyState,
  setLocale(locale: Locale) {},
  logIn(session: Session) {},
  logOut() {},
  async refreshSession() {},
});

const tokenDelta = 30 * 24 * 60 * 60 * 1000 - 10000;

export function SessionProvider({ children }: { children?: ReactNode }) {
  const [state, setState] = useState(emptyState);
  const [loading, setLoading] = useState(Boolean(startCookie));

  const setLocale = useCallback((locale: Locale) => {
    setState((state) => ({ ...state, locale }));
    setCookie("locale", locale);
  }, []);

  const logIn = useCallback(
    ({ token, email, name, locale, handheld_iba_enabled }: Session) => {
      setState({
        token,
        email,
        name,
        locale: parseLocale(locale),
        handheld_iba_enabled,
      });
      setCookie("token", token, tokenDelta);
      setCookie("locale", locale);
    },
    []
  );

  const logOut = useCallback(() => {
    setState(emptyState);
    clearCookie("token");
    clearCookie("locale");
  }, []);

  const refreshSession = useCallback(async () => {
    if (!state.token) {
      return;
    }
    const session = await loginRefresh({ token: state.token });
    logIn(session);
  }, [logIn, state.token]);

  useEffect(() => {
    (async () => {
      if (startCookie) {
        try {
          const session = await loginRefresh({ token: startCookie });
          logIn(session);
        } catch {
          clearCookie("token");
          clearCookie("locale");
        }
        setLoading(false);
      }
    })();
  }, [logIn]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Context.Provider
      value={{ ...state, setLocale, logIn, logOut, refreshSession }}
    >
      {children}
    </Context.Provider>
  );
}

export function useSession() {
  return useContext(Context);
}
