import React, { ReactNode, useContext } from "react";
import { T } from "lib/language";
import { ThemeContext } from "index";
import openNewTab from "images/openNewTab.svg";
import styles from "./Info.module.scss";

function Resource({ children, href }: { children: ReactNode; href: string }) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      <span className="ctaLink">{children}</span>
      <img src={openNewTab} alt="open in new tab" />
    </a>
  );
}

function Info() {
  const theme = useContext(ThemeContext);

  return (
    <div className="dashboardSection">
      <h1>
        <T id="info" />
      </h1>
      <div className={styles.resources}>
        {theme?.links.faq ? (
          <Resource href={theme?.links.faq}>
            <T id="info.faq" />
          </Resource>
        ) : null}
        {theme?.links.quickGuide ? (
          <Resource href={theme?.links.quickGuide}>
            <T id="info.quickGuide" />
          </Resource>
        ) : null}
        {theme?.links.privacyPolicy ? (
          <Resource href={theme?.links.privacyPolicy}>
            <T id="info.privacyPolicy" />
          </Resource>
        ) : null}
        {theme?.links.termsOfUse ? (
          <Resource href={theme?.links.termsOfUse}>
            <T id="info.termsOfUse" />
          </Resource>
        ) : null}
      </div>
    </div>
  );
}

export default Info;
