import React, { ReactNode, useEffect, useState } from "react";
import BasePopup from "./BasePopup";
import styles from "./Dropdown.module.scss";

type props<O> = {
  children?: React.ReactNode;
  options: O[];
  getKey: (o: O) => number | string;
  render: (o: O) => ReactNode;
  getDisabled?: (o: O) => boolean;
  onSelect?: (o: O) => void;
  disabled?: boolean;
  caller: string;
};

function Dropdown<K>({
  children,
  options,
  getKey,
  render,
  getDisabled,
  onSelect,
  disabled,
  caller,
}: props<K>) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const getStyle = (caller: string): string => {
    let output = styles.popup;

    switch (caller) {
      case "accountPage":
        output = styles.popupAccountPage;
        break;
      case "loginPage":
        output = styles.popupLoginPage;
        break;
      case "listActions":
        output = styles.popupListPage;
        break;
      case "measurementActions":
        output = styles.popupDetailsPage;
        break;
      default:
        output = styles.popup;
    }

    return output;
  };

  return (
    <BasePopup
      button={children}
      popupClass={getStyle(caller)}
      open={open}
      onOpen={setOpen}
      disabled={disabled}
    >
      {options.map((option) => {
        return (
          <button
            key={getKey(option)}
            className={styles.button}
            onClick={() => {
              setOpen(false);
              if (onSelect) {
                onSelect(option);
              }
            }}
            disabled={getDisabled ? getDisabled(option) : false}
          >
            {render(option)}
          </button>
        );
      })}
    </BasePopup>
  );
}

export default Dropdown;
