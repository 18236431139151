import { ReactComponent as Account } from "images/sidebar/account.svg";
import { ReactComponent as IBA } from "images/sidebar/iba.svg";
import { ReactComponent as Info } from "images/sidebar/info.svg";
import { ReactComponent as Measurements } from "images/sidebar/measurements.svg";
import { MobileContext } from "./Application";
import { NavLink } from "react-router-dom";
import { ReactComponent as ReleaseNotes } from "images/sidebar/releaseNotes.svg";
import { ReactComponent as Support } from "images/sidebar/support.svg";
import { T } from "lib/language";
import { ThemeContext } from "index";
import { ReactComponent as Webshop } from "images/sidebar/webshop.svg";
import logo from "images/logo.svg";
import logoDJ from "images/DJ/dj-LogoWhite.png";
import styles from "./Sidebar.module.scss";
import { useContext } from "react";
import { useSession } from "lib/session";

function Sidebar() {
  const { name, logOut } = useSession();
  const theme = useContext(ThemeContext);
  const { menuOpen, setMenuOpen, isDesktop } = useContext(MobileContext);
  const getSupportMailLink = (): string => {
    return "mailto:" + theme?.links.supportEmail;
  };

  const handleMenuItemClick = () => {
    setMenuOpen(isDesktop);
  };

  return menuOpen ? (
    <nav className={styles.root}>
      <img
        src={theme && theme.name === "DJ" ? logoDJ : logo}
        className={styles.logo}
        alt="Logo"
      />
      <div className={styles.sectionMain}>
        <T id="sidebar.main" />
      </div>
      <NavLink
        to="/measurements"
        onClick={handleMenuItemClick}
        activeClassName={styles.active}
      >
        <Measurements />
        <T id="measurements" />
      </NavLink>
      <NavLink
        to="/iba"
        onClick={handleMenuItemClick}
        activeClassName={styles.active}
      >
        <IBA />
        <T id="iba" />
      </NavLink>
      <NavLink
        to="/account"
        onClick={handleMenuItemClick}
        activeClassName={styles.active}
      >
        <Account />
        <T id="account" />
      </NavLink>
      {theme && theme.name === "GS" ? (
        <NavLink
          to="/webshop"
          onClick={handleMenuItemClick}
          activeClassName={styles.active}
        >
          <Webshop />
          <T id="webshop" />
        </NavLink>
      ) : null}
      <div className={styles.sectionHelp}>
        <T id="sidebar.help" />
      </div>
      <NavLink
        to="/info"
        onClick={handleMenuItemClick}
        activeClassName={styles.active}
      >
        <Info />
        <T id="info" />
      </NavLink>
      <NavLink
        to="/release-notes"
        onClick={handleMenuItemClick}
        activeClassName={styles.active}
      >
        <ReleaseNotes />
        <T id="releaseNotes" />
      </NavLink>
      <a href={getSupportMailLink()}>
        <Support />
        <T id="support" />
      </a>
      <div className={styles.userSection}>
        <div className={styles.userName}>{name}</div>
        <button onClick={logOut}>
          <T id="logOut" />
          {" >"}
        </button>
      </div>
    </nav>
  ) : (
    <div
      style={{
        position: "absolute",
        top: 5,
        left: 5,
        zIndex: 50,
      }}
      onClick={() => setMenuOpen(true)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          width: "1rem",
          height: "1rem",
          backgroundColor: "var(--color2)",
          padding: 3,
          borderRadius: 4,
          cursor: "pointer",
          border: "2px solid var(--color1)",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "var(--color5)",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "var(--color5)",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "var(--color5)",
          }}
        ></div>
      </div>
    </div>
  );
}

export default Sidebar;
