import { Redirect, Route, Switch } from "react-router-dom";
import Confirm from "pages/resetPassword/Confirm";
import Login from "pages/login/Login";
import ResetPassword from "pages/resetPassword/ResetPassword";
import { ThemeContext } from "index";
import Verify from "pages/verify/Verify";
import { classes } from "lib/helpers";
import logo from "images/logo.svg";
import logoText from "images/logoText.svg";
import logoTextDJ from "images/DJ/dj-LogoGray.png";
import styles from "./LoggedOut.module.scss";
import { useContext } from "react";

function LoggedOut() {
  const theme = useContext(ThemeContext);

  return (
    <>
      <header
        className={classes(
          styles.header,
          theme && theme.name !== "GS" ? styles.center : null
        )}
      >
        {theme && theme.name === "GS" ? (
          <img className={styles.logo} src={logo} alt="Grainsense Logo" />
        ) : null}
        <img
          className={classes(
            styles.logoText,
            theme && theme.name !== "GS" ? styles.bigger : null
          )}
          src={theme && theme.name === "DJ" ? logoTextDJ : logoText}
          alt="Logo"
        />
      </header>
      <main className={styles.main}>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/login/reset-password/confirm">
            <Confirm />
          </Route>
          <Route exact path="/login/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/login/verify">
            <Verify />
          </Route>
          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </main>
    </>
  );
}

export default LoggedOut;
