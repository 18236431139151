import "style/index.scss";
import * as Sentry from "@sentry/react";
import * as yup from "yup";
import React, { createContext, useState } from "react";
import Application from "templates/Application";
import { BrowserTracing } from "@sentry/tracing";
import { LanguageProvider } from "lib/language";
import { MapsProvider } from "lib/map";
import { MessageProvider } from "lib/message";
import ReactDOM from "react-dom";
import { SessionProvider } from "lib/session";

if (
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "staging"
) {
  Sentry.init({
    dsn: "https://6ab7bcebc0fa49e8a17c8a6d52b0034d@o323240.ingest.sentry.io/6659259",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  });
}

export const ThemeContext = createContext<Theme | undefined>(undefined);

type Colors = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
  color7: string;
  color8: string;
  color9: string;
  color10: string;
  color11: string;
  color12: string;
  color13: string;
  color14: string;
  color15: string;
  color16: string;
  color17: string;
  color18: string;
  color19: string;
  color20: string;
  color21: string;
};
type Language = { default: string; available: [string] };
type Links = {
  privacyPolicy: string | undefined;
  termsOfUse: string | undefined;
  faq: string | undefined;
  quickGuide: string | undefined;
  supportEmail: string;
};
type Theme = {
  name: string;
  title: string;
  favicon: string;
  colors: Colors;
  language: Language;
  links: Links;
};

const changeWindowTitle = (title: string): void => {
  document.title = title;
};

const addTheme = (
  themeString: string | undefined,
  setTheme: React.Dispatch<React.SetStateAction<Theme | undefined>>
) => {
  const root = document.documentElement;
  const hexColorRegex =
    /^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const invalidColorMessage = "Invalid hex color";
  const colorsSchema = yup.object().shape({
    color1: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color2: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color3: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color4: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color5: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color6: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color7: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color8: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color9: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color10: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color11: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color12: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color13: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color14: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color15: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color16: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color17: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color18: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color19: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color20: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color21: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
  });
  const languageSchema = yup
    .object()
    .shape({
      default: yup.string().defined(),
      available: yup.array().of(yup.string()).defined(),
    })
    .defined();
  const linksSchema = yup
    .object()
    .shape({
      privacyPolicy: yup.string().optional(),
      termsOfUse: yup.string().optional(),
      faq: yup.string().optional(),
      quickGuide: yup.string().optional(),
      supportEmail: yup.string().defined(),
    })
    .defined();
  const themeSchema = yup.object().shape({
    language: languageSchema,
    title: yup.string().defined(),
    favicon: yup.string().defined(),
    name: yup.string().defined(),
    links: linksSchema,
    colors: colorsSchema.required().defined(),
  });

  const setFavicon = (url: string) => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = "/" + url;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const setDescription = (content: string) => {
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = content;
    document.getElementsByTagName("head")[0].appendChild(meta);
  };

  if (themeString) {
    try {
      let theme: Theme = JSON.parse(themeString);

      themeSchema
        .validate(theme)
        .then((validatedData: Theme) => {
          setTheme(validatedData);
          changeWindowTitle(validatedData.title);
          setFavicon(validatedData.favicon);
          setDescription(
            validatedData.name === "GS"
              ? "Grainsense measurement management app"
              : "Measurement management app"
          );
          for (const color in validatedData.colors) {
            if (validatedData.colors.hasOwnProperty(color)) {
              root.style.setProperty(
                `--${color}`,
                validatedData.colors[color as keyof Colors]
              );
            }
          }
        })
        .catch((err) => {
          console.error("Validation error:", err.errors);
        });
    } catch (e) {
      console.error("Invalid JSON:", e);
    }
  } else {
    console.log("Missing REACT_APP_THEME");
  }
};

const RootComponent = () => {
  const [theme, setTheme] = useState<Theme>();
  const themeString = process.env.REACT_APP_THEME;
  if (!theme && themeString) {
    addTheme(themeString, setTheme);
  }

  return (
    <ThemeContext.Provider value={theme}>
      <MapsProvider>
        <SessionProvider>
          <LanguageProvider>
            <MessageProvider>
              <Application />
            </MessageProvider>
          </LanguageProvider>
        </SessionProvider>
      </MapsProvider>
    </ThemeContext.Provider>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById("root"));
