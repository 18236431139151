import React, { useContext, useEffect } from "react";
import { MobileContext } from "templates/Application";
import { classes } from "lib/helpers";
import popupArrow from "images/popupArrow.svg";
import styles from "./BasePopup.module.scss";

type props = {
  button?: React.ReactNode;
  children?: React.ReactNode;
  popupClass?: string;
  disabled?: boolean;
  open?: boolean;
  onOpen?: (open: boolean) => void;
};

function BasePopup({
  button,
  children,
  popupClass,
  disabled,
  open,
  onOpen,
}: props) {
  const { menuOpen, setMenuOpen, isDesktop } = useContext(MobileContext);

  useEffect(() => {
    if (onOpen) {
      const clickHandler = () => onOpen(false);
      document.addEventListener("click", clickHandler);
      return () => document.removeEventListener("click", clickHandler);
    }
  }, [onOpen]);

  useEffect(() => {
    if (open) {
      if (!isDesktop && menuOpen) {
        setMenuOpen(false);
      }
    }
  }, [isDesktop, menuOpen, open, setMenuOpen]);

  return (
    <div className={styles.root} onClick={(e) => e.stopPropagation()}>
      <button
        className={classes(styles.button, open && styles.opened)}
        onClick={(e) => {
          if (onOpen) {
            onOpen(!open);
          }
        }}
        disabled={disabled}
      >
        {button}
        <img src={popupArrow} alt="Popup arrow" />
      </button>
      {open ? (
        <div className={classes(styles.popup, popupClass)}>{children}</div>
      ) : null}
    </div>
  );
}

export default BasePopup;
