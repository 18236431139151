import { AccountType, getAccount } from "api/account";
import { Redirect, Route, Switch } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import Account from "pages/account/Account";
import IBA from "pages/iba/IBA";
import Info from "pages/info/Info";
import Measurements from "pages/measurements/Measurements";
import ReleaseNotes from "pages/releaseNotes/ReleaseNotes";
import RolesDialog from "pages/account/RolesDialog";
import Sidebar from "./Sidebar";
import Webshop from "pages/webshop/Webshop";
import styles from "./Dashboard.module.scss";

export const AccountContext = createContext<AccountType | undefined>(undefined);

function Dashboard() {
  const [account, setAccount] = useState<AccountType>();
  const [rolesDialogHidden, setRolesDialogHidden] = useState<boolean>(false);

  useEffect(() => {
    if (!account) {
      getAccount()
        .then((response) => {
          setAccount(response);
        })
        .catch(() => console.log("Failed to fetch account"));
    }
  }, [account]);

  return account ? (
    <AccountContext.Provider value={account}>
      <div className={styles.root}>
        <Sidebar />
        <main>
          <Switch>
            <Route path="/measurements">
              <Measurements />
            </Route>
            <Route exact path="/iba">
              <IBA />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/webshop">
              <Webshop />
            </Route>
            <Route exact path="/info">
              <Info />
            </Route>
            <Route exact path="/release-notes">
              <ReleaseNotes />
            </Route>
            <Route>
              <Redirect to="/measurements" />
            </Route>
          </Switch>
        </main>
        {account && account.roles?.length === 0 ? (
          <RolesDialog
            rolesDialogHidden={rolesDialogHidden}
            setRolesDialogHidden={setRolesDialogHidden}
          />
        ) : null}
      </div>
    </AccountContext.Provider>
  ) : null;
}

export default Dashboard;
