import {
  CalibrationText,
  calibrations,
  getCalibrationTextId,
} from "lib/calibrations";
import {
  Filter as Input,
  defaultFilters,
  filterEquals,
} from "api/measurements";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BasePopup from "components/popup/BasePopup";
import Checkbox from "components/Checkbox";
import DateInput from "components/DateInput";
import FillCheckbox from "components/FillCheckbox";
import { ReactComponent as FiltersIcon } from "images/filters.svg";
import FormInput from "components/FormInput";
import { T } from "lib/language";
import { classes } from "lib/helpers";
import styles from "./Filter.module.scss";
import { useDevices } from "api/iba";
import useMediaQuery from "lib/useMediaQuery";

function MinMaxRangeComponent({
  textTag,
  minValueCurrent,
  maxValueCurrent,
  onChangeMin,
  onChangeMax,
}: {
  textTag: string;
  minValueCurrent: number;
  maxValueCurrent: number;
  onChangeMin: React.Dispatch<React.SetStateAction<number>>;
  onChangeMax: React.Dispatch<React.SetStateAction<number>>;
}) {
  const minValue = 0;
  const maxValue = 100;
  const stepSize = 0.1;

  return (
    <div className={styles.minMaxRangeContainer}>
      <div className={styles.label}>
        <T id={textTag} /> %
      </div>
      <div className={styles.minMaxRangeComponents}>
        <input
          type="number"
          required
          min={minValue}
          max={maxValue}
          step={stepSize}
          value={minValueCurrent || minValue}
          onChange={(e) => {
            if (e.target.value === "") {
              onChangeMin(minValue);
            }
            const value = e.target.valueAsNumber;
            if (!isNaN(value)) {
              onChangeMin(value);
            }
          }}
          onBlur={(e) => {
            const value = e.target.valueAsNumber;
            if (!isNaN(value)) {
              onChangeMin(
                value < minValue
                  ? minValue
                  : value >= maxValueCurrent
                  ? maxValueCurrent - stepSize
                  : value
              );
            }
          }}
        />
        {" - "}
        <input
          type="number"
          required
          min={minValue}
          max={maxValue}
          step={stepSize}
          value={maxValueCurrent || maxValue}
          onChange={(e) => {
            if (e.target.value === "") {
              onChangeMax(maxValue);
            }
            const value = e.target.valueAsNumber;
            if (!isNaN(value)) {
              onChangeMax(value);
            }
          }}
          onBlur={(e) => {
            const value = e.target.valueAsNumber;
            if (!isNaN(value)) {
              onChangeMax(
                value > maxValue
                  ? maxValue
                  : value <= minValueCurrent
                  ? minValueCurrent + stepSize
                  : value
              );
            }
          }}
        />
      </div>
    </div>
  );
}

function Filter({
  filters,
  onFilters,
}: {
  filters: Input;
  onFilters: (filters: Input) => void;
}) {
  const [open, setOpen] = useState(false);
  const [uuids, setUuids] = useState(filters.calibration__uuid__in);
  const [from, setFrom] = useState(filters.measured_at__gte);
  const [to, setTo] = useState(filters.measured_at__lte);
  const [commentContains, setCommentContains] = useState(
    filters.comment__contains
  );
  const [nameContains, setNameContains] = useState(filters.name__contains);
  const [proteinMin, setProteinMin] = useState(filters.protein_min);
  const [proteinMax, setProteinMax] = useState(filters.protein_max);
  const [moistureMin, setMoistureMin] = useState(filters.moisture_min);
  const [moistureMax, setMoistureMax] = useState(filters.moisture_max);
  const [carbohydrateMin, setCarbohydrateMin] = useState(
    filters.carbohydrate_min
  );
  const [carbohydrateMax, setCarbohydrateMax] = useState(
    filters.carbohydrate_max
  );
  const [oilMin, setOilMin] = useState(filters.oil_min);
  const [oilMax, setOilMax] = useState(filters.oil_max);
  const [selectedDevices, setSelectedDevices] = useState(filters.device__in);

  const currentFilters = useMemo(
    () => ({
      calibration__uuid__in: uuids,
      measured_at__gte: from,
      measured_at__lte: to,
      comment__contains: commentContains,
      name__contains: nameContains,
      protein_min: proteinMin,
      protein_max: proteinMax,
      moisture_min: moistureMin,
      moisture_max: moistureMax,
      carbohydrate_min: carbohydrateMin,
      carbohydrate_max: carbohydrateMax,
      oil_min: oilMin,
      oil_max: oilMax,
      device__in: selectedDevices,
    }),
    [
      from,
      to,
      uuids,
      commentContains,
      nameContains,
      proteinMin,
      proteinMax,
      moistureMin,
      moistureMax,
      carbohydrateMin,
      carbohydrateMax,
      oilMin,
      oilMax,
      selectedDevices,
    ]
  );
  const setFilters = useCallback((filters) => {
    setUuids(filters.calibration__uuid__in);
    setFrom(filters.measured_at__gte);
    setTo(filters.measured_at__lte);
    setCommentContains(filters.comment__contains);
    setNameContains(filters.name__contains);
    setProteinMin(filters.protein_min);
    setProteinMax(filters.protein_max);
    setMoistureMin(filters.moisture_min);
    setMoistureMax(filters.moisture_max);
    setCarbohydrateMin(filters.carbohydrate_min);
    setCarbohydrateMax(filters.carbohydrate_max);
    setOilMin(filters.oil_min);
    setOilMax(filters.oil_max);
    setSelectedDevices(filters.device__in);
  }, []);

  const [userHasdevices] = useDevices();

  useEffect(() => {
    setFilters(filters);
  }, [filters, setFilters]);

  const isDirty = useMemo(
    () =>
      uuids.join(",") !== filters.calibration__uuid__in.join(",") ||
      from?.toISOString() !== filters.measured_at__gte?.toISOString() ||
      to?.toISOString() !== filters.measured_at__lte?.toISOString() ||
      commentContains !== filters.comment__contains ||
      nameContains !== filters.name__contains ||
      proteinMin !== filters.protein_min ||
      proteinMax !== filters.protein_max ||
      moistureMin !== filters.moisture_min ||
      moistureMax !== filters.moisture_max ||
      carbohydrateMin !== filters.carbohydrate_min ||
      carbohydrateMax !== filters.carbohydrate_max ||
      oilMin !== filters.oil_min ||
      oilMax !== filters.oil_max ||
      selectedDevices !== filters.device__in,
    [
      filters,
      from,
      to,
      uuids,
      commentContains,
      nameContains,
      proteinMin,
      proteinMax,
      moistureMin,
      moistureMax,
      carbohydrateMin,
      carbohydrateMax,
      oilMin,
      oilMax,
      selectedDevices,
    ]
  );

  const isDefault = useMemo(
    () => filterEquals(filters, defaultFilters),
    [filters]
  );

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters);
    onFilters(defaultFilters);
    setOpen(false);
  }, [onFilters, setFilters]);

  const applyFilters = useCallback(() => {
    onFilters(currentFilters);
    setOpen(false);
  }, [onFilters, currentFilters]);

  useEffect(() => {
    if (open) {
      document.querySelectorAll("div.DayPickerInput input").forEach((item) => {
        item.setAttribute("readonly", "true");
      });
    }
  }, [open]);

  type SelectionProps = {
    children?: React.ReactNode;
  };

  const CalibrationSelection: React.FC<SelectionProps> = ({ children }) => {
    return (
      <>
        <div className={styles.fillCheckboxesTitle}>{children}</div>
        <div className={styles.fillCheckboxes}>
          {calibrations.map((c) => (
            <FillCheckbox
              key={c}
              checked={uuids?.includes(c)}
              onChecked={(checked) => {
                const newUuids = uuids
                  ? checked
                    ? [...uuids, c]
                    : uuids.filter((uuid) => uuid !== c)
                  : checked
                  ? [c]
                  : [];
                setUuids(newUuids.sort());
              }}
            >
              <CalibrationText calibration={c} />
            </FillCheckbox>
          ))}
        </div>
      </>
    );
  };

  const DevicesSelection: React.FC<SelectionProps> = ({ children }) => {
    return (
      <>
        <div className={styles.fillCheckboxesTitle}>{children}</div>
        <div className={styles.fillCheckboxes}>
          {userHasdevices?.map((d) => (
            <FillCheckbox
              key={d.serial_number}
              checked={selectedDevices?.includes(d.serial_number)}
              onChecked={(checked) => {
                const newSelectedDevices = selectedDevices
                  ? checked
                    ? [...selectedDevices, d.serial_number]
                    : selectedDevices.filter(
                        (selectedDevices) => selectedDevices !== d.serial_number
                      )
                  : checked
                  ? [d.serial_number]
                  : [];
                setSelectedDevices(newSelectedDevices.sort());
              }}
            >
              {d.serial_number}
            </FillCheckbox>
          ))}
        </div>
      </>
    );
  };

  const MobileView = () => {
    return (
      <BasePopup
        popupClass={styles.popupMobile}
        open={open}
        onOpen={setOpen}
        button={
          <>
            <FiltersIcon />
            <T id="measurements.filters" />
          </>
        }
      >
        <div className={styles.popupContent}>
          <CalibrationSelection>
            <T id="measurement.species" />
          </CalibrationSelection>
          <DevicesSelection>
            <T id="iba.device" />
          </DevicesSelection>
          <MinMaxFilterContainer />
          <ActionsContainer />
        </div>
      </BasePopup>
    );
  };

  const ActionsContainer = () => {
    return (
      <div className={styles.actions}>
        <DateInput
          value={from}
          styleSize="medium"
          onChange={setFrom}
          type="start"
        >
          <T id="measurements.filters.from" />
        </DateInput>
        <DateInput value={to} styleSize="medium" onChange={setTo} type="end">
          <T id="measurements.filters.to" />
        </DateInput>
        <FormInput
          name="name_contains"
          styleSize={"medium"}
          onChangeValue={setNameContains}
          value={nameContains}
        >
          <T id="measurement.filter.name_contains" />
        </FormInput>
        <FormInput
          name="comment_contains"
          styleSize={"medium"}
          onChangeValue={setCommentContains}
          value={commentContains}
        >
          <T id="measurement.filter.comment_contains" />
        </FormInput>
        <div className={styles.buttonGroup}>
          <button
            className="cta medium secondary"
            disabled={isDefault}
            onClick={resetFilters}
          >
            <T id="measurements.filters.reset" />
          </button>
          <button
            className="cta medium"
            disabled={!isDirty}
            onClick={applyFilters}
          >
            <T id="measurements.filters.apply" />
          </button>
        </div>
      </div>
    );
  };

  const MinMaxFilterContainer = () => {
    return (
      <div className={styles.minMaxSliderContainer}>
        <MinMaxRangeComponent
          textTag={"measurement.protein"}
          minValueCurrent={proteinMin}
          onChangeMin={setProteinMin}
          maxValueCurrent={proteinMax}
          onChangeMax={setProteinMax}
        />
        <MinMaxRangeComponent
          textTag={"measurement.moisture"}
          minValueCurrent={moistureMin}
          onChangeMin={setMoistureMin}
          maxValueCurrent={moistureMax}
          onChangeMax={setMoistureMax}
        />
        <MinMaxRangeComponent
          textTag={"measurement.carbohydrate"}
          minValueCurrent={carbohydrateMin}
          onChangeMin={setCarbohydrateMin}
          maxValueCurrent={carbohydrateMax}
          onChangeMax={setCarbohydrateMax}
        />
        <MinMaxRangeComponent
          textTag={"measurement.oil"}
          minValueCurrent={oilMin}
          onChangeMin={setOilMin}
          maxValueCurrent={oilMax}
          onChangeMax={setOilMax}
        />
      </div>
    );
  };

  return useMediaQuery("(min-width: 1024px)") ? (
    <BasePopup
      popupClass={styles.popup}
      open={open}
      onOpen={setOpen}
      button={
        <>
          <FiltersIcon />
          <T id="measurements.filters" />
        </>
      }
    >
      <div className={styles.checkboxes}>
        {calibrations.map((c) => (
          <Checkbox
            key={c}
            checked={uuids?.includes(c)}
            onChecked={(checked) => {
              const newUuids = uuids
                ? checked
                  ? [...uuids, c]
                  : uuids.filter((uuid) => uuid !== c)
                : checked
                ? [c]
                : [];
              setUuids(newUuids.sort());
            }}
          >
            <CalibrationText calibration={c} />
          </Checkbox>
        ))}
      </div>
      <ActionsContainer />
      <MinMaxFilterContainer />
      <div className={classes(styles.devicesContainer, styles.checkboxes)}>
        <T id="iba.device" />:
        {userHasdevices?.map((d) => (
          <Checkbox
            key={d.serial_number}
            checked={selectedDevices?.includes(d.serial_number)}
            onChecked={(checked) => {
              const newSelectedDevices = selectedDevices
                ? checked
                  ? [...selectedDevices, d.serial_number]
                  : selectedDevices.filter(
                      (selectedDevices) => selectedDevices !== d.serial_number
                    )
                : checked
                ? [d.serial_number]
                : [];
              setSelectedDevices(newSelectedDevices.sort());
            }}
          >
            {d.serial_number}
          </Checkbox>
        ))}
      </div>
    </BasePopup>
  ) : (
    <MobileView />
  );
}

export default Filter;
