import * as Sentry from "@sentry/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import Dashboard from "./Dashboard";
import LoggedOut from "./LoggedOut";
import useMediaQuery from "lib/useMediaQuery";
import { useSession } from "lib/session";

interface MobileContextType {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
}

export const MobileContext = createContext<MobileContextType>({
  menuOpen: true,
  setMenuOpen: () => {},
  isDesktop: true,
});

function Application() {
  const { token } = useSession();
  const isDesktop = useMediaQuery("(min-width: 750px)");
  const [menuOpen, setMenuOpen] = useState(isDesktop);

  useEffect(() => {
    setMenuOpen(isDesktop);
  }, [isDesktop]);

  return (
    <MobileContext.Provider value={{ menuOpen, setMenuOpen, isDesktop }}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/verify"
            render={(props) => {
              return (
                <Redirect
                  to={{
                    pathname: "/login/verify",
                    search: props.location.search,
                  }}
                />
              );
            }}
          />
          <Route
            exact
            path="/reset_password/confirm"
            render={(props) => {
              return (
                <Redirect
                  to={{
                    pathname: "/login/reset-password/confirm",
                    search: props.location.search,
                  }}
                />
              );
            }}
          />
          <Route path="/login">
            {token ? <Redirect to="/measurements" /> : <LoggedOut />}
          </Route>
          <Route>{token ? <Dashboard /> : <Redirect to="/login" />}</Route>
        </Switch>
      </BrowserRouter>
    </MobileContext.Provider>
  );
}

export default Sentry.withProfiler(Application);
