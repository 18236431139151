import * as yup from "yup";
import { api } from "lib/api";

const filters = yup.object({
  calibration__uuid__in: yup.array(yup.string().uuid().required()).required(),
  measured_at__gte: yup.date(),
  measured_at__lte: yup.date(),
  comment__contains: yup.string().required(),
  name__contains: yup.string().required(),
  protein_min: yup.number().required(),
  protein_max: yup.number().required(),
  moisture_min: yup.number().required(),
  moisture_max: yup.number().required(),
  carbohydrate_min: yup.number().required(),
  carbohydrate_max: yup.number().required(),
  oil_min: yup.number().required(),
  oil_max: yup.number().required(),
  device__in: yup.array(yup.number().required()).required(),
});
const pagination = yup.object({
  limit: yup.number(),
  offset: yup.number().required(),
});
const input = yup
  .object({
    ordering: yup.string().required().default("-measured_at"),
  })
  .concat(filters)
  .concat(pagination);

const measurementSchema = yup.object({
  uuid: yup.string().uuid().required(),
  calibration: yup.string().uuid().required(),
  device: yup.number().required(),
  comment: yup.string(),
  measured_at: yup.date().required(),
  gps_latitude: yup.number().nullable().defined(),
  gps_longitude: yup.number().nullable().defined(),
  total: yup.number().required(),
  protein: yup.number().required(),
  moisture: yup.number().required(),
  carbohydrate: yup.number().required(),
  oil: yup.number().required(),
  gluten_iso: yup.number().nullable().defined(),
  gluten_gost: yup.number().nullable().defined(),
  nitrogen: yup.number().nullable().defined(),
  protein_basis: yup.string().oneOf(["wet", "dry", "fixed"]),
  protein_fixed_percentage: yup.number().nullable().defined(),
  moisture_basis: yup.string().oneOf(["wet", "dry", "fixed"]),
  moisture_fixed_percentage: yup.number().nullable().defined(),
  carbohydrate_basis: yup.string().oneOf(["wet", "dry", "fixed"]),
  carbohydrate_fixed_percentage: yup.number().nullable().defined(),
  oil_basis: yup.string().oneOf(["wet", "dry", "fixed"]),
  oil_fixed_percentage: yup.number().nullable().defined(),
  area_correction_created_at: yup.date().nullable().defined(),
  protein_area_offset: yup.number().nullable().defined(),
  moisture_area_offset: yup.number().nullable().defined(),
  oil_area_offset: yup.number().nullable().defined(),
  device_correction_created_at: yup.date().nullable().defined(),
  protein_device_offset: yup.number().nullable().defined(),
  moisture_device_offset: yup.number().nullable().defined(),
  oil_device_offset: yup.number().nullable().defined(),
  gluten_iso_offset: yup.number().nullable().defined(),
  gluten_gost_offset: yup.number().nullable().defined(),
  sunflower_unpeeled_estimate_oil: yup.number().nullable().optional(),
  sunflower_unpeeled_estimate_moisture: yup.number().nullable().optional(),
  attachment: yup.string().nullable().defined(),
  hectolitre: yup.number().nullable(),
});

const measurementsListItemSchema = yup.object({
  uuid: yup.string().uuid().required(),
  calibration: yup.string().uuid().required(),
  created_at: yup.date().required(),
  measured_at: yup.date().required(),
  comment: yup.string(),
  gps_latitude: yup.number().nullable(),
  gps_longitude: yup.number().nullable(),
  protein: yup.number().nullable().defined(),
  moisture: yup.number().nullable().defined(),
  carbohydrate: yup.number().nullable().defined(),
  oil: yup.number().nullable().defined(),
  attachment: yup.string().nullable().defined(),
  comprehensiveness: yup.number().nullable().defined(),
  isGroup: yup.boolean().defined(),
  groupName: yup.string().nullable(),
  total: yup.number().defined().nullable(),
  nitrogen: yup.number().defined().nullable(),
  gluten_iso: yup.number().defined().nullable(),
  gluten_gost: yup.number().defined().nullable(),
  measurements: yup.array().of(
    yup.mixed().test("is-valid", "Must be a string or object", (value) => {
      if (typeof value === "string") {
        return true;
      }
      return yup
        .object()
        .shape({
          uuid: yup.string().required(),
          gps_latitude: yup.number().required(),
          gps_longitude: yup.number().required(),
        })
        .isValidSync(value);
    })
  ),
  device: yup.number().nullable(),
});

const measurementGroupSchema = yup.object({
  uuid: yup.string().uuid().required(),
  calibration: yup.string().uuid().required(),
  comment: yup.string(),
  measured_at: yup.date().required(),
  protein: yup.number().nullable().defined(),
  moisture: yup.number().nullable().defined(),
  carbohydrate: yup.number().nullable().defined(),
  oil: yup.number().nullable().defined(),
  attachment: yup.string().nullable().defined(),
  comprehensiveness: yup.number().nullable().defined(),
  groupName: yup.string(),
  measurements: yup.array(measurementSchema).required(),
  group_type: yup.string().nullable(),
});

const output = yup.object({
  count: yup.number().required(),
  results: yup.array(measurementsListItemSchema).required(),
});

const deleteMeasurementGroupsSchema = yup.object({
  uuids: yup.array().required(),
  delete_measurements: yup.boolean().required(),
});

export type Filter = yup.Asserts<typeof filters>;
export type Pagination = yup.Asserts<typeof pagination>;
export type Input = yup.Asserts<typeof input>;
export type MeasurementGroup = yup.Asserts<typeof measurementGroupSchema>;
export type Measurement = yup.Asserts<typeof measurementSchema>;
export type MeasurementsListItem = yup.Asserts<
  typeof measurementsListItemSchema
>;
export const updateAttachmentSchema = yup
  .object()
  .shape({ attachment: yup.mixed() });

export const defaultFilters: Filter = {
  calibration__uuid__in: [],
  measured_at__gte: undefined,
  measured_at__lte: undefined,
  comment__contains: "",
  name__contains: "",
  protein_min: 0,
  protein_max: 100,
  moisture_min: 0,
  moisture_max: 100,
  carbohydrate_min: 0,
  carbohydrate_max: 100,
  oil_min: 0,
  oil_max: 100,
  device__in: [],
};

export type MinMax = { min: number; max: number };
export type RangeValues = {
  carbohydrate: MinMax;
  protein: MinMax;
  moisture: MinMax;
  oil: MinMax;
};

export function getRangeValues(m: MeasurementGroup): RangeValues | null {
  let output = null;

  function getMinMaxValues(m: MeasurementGroup, calibration: string): MinMax {
    const output = { min: 0, max: 0 };

    switch (calibration) {
      case "protein":
        output.min = Math.min(
          ...m.measurements.map((item: Measurement) => item.protein)
        );
        output.max = Math.max(
          ...m.measurements.map((item: Measurement) => item.protein)
        );
        break;

      case "carbohydrate":
        output.min = Math.min(
          ...m.measurements.map((item: Measurement) => item.carbohydrate)
        );
        output.max = Math.max(
          ...m.measurements.map((item: Measurement) => item.carbohydrate)
        );
        break;

      case "moisture":
        output.min = Math.min(
          ...m.measurements.map((item: Measurement) => item.moisture)
        );
        output.max = Math.max(
          ...m.measurements.map((item: Measurement) => item.moisture)
        );
        break;

      case "oil":
        output.min = Math.min(
          ...m.measurements.map((item: Measurement) => item.oil)
        );
        output.max = Math.max(
          ...m.measurements.map((item: Measurement) => item.oil)
        );
        break;
    }

    return output;
  }

  if (m.measurements !== null) {
    output = {
      carbohydrate: getMinMaxValues(m, "carbohydrate"),
      protein: getMinMaxValues(m, "protein"),
      moisture: getMinMaxValues(m, "moisture"),
      oil: getMinMaxValues(m, "oil"),
    };
  }

  return output;
}

export const defaultInput: Input = {
  ordering: "-measured_at",
  limit: 10,
  offset: 0,
  ...defaultFilters,
};

export function filterEquals(a: Filter, b: Filter) {
  return (
    a.calibration__uuid__in.join(",") === b.calibration__uuid__in.join(",") &&
    a.measured_at__gte?.toISOString() === b.measured_at__gte?.toISOString() &&
    a.measured_at__lte?.toISOString() === b.measured_at__lte?.toISOString() &&
    a.comment__contains === b.comment__contains &&
    a.name__contains === b.name__contains &&
    a.protein_min === b.protein_min &&
    a.protein_max === b.protein_max &&
    a.moisture_min === b.moisture_min &&
    a.moisture_max === b.moisture_max &&
    a.carbohydrate_min === b.carbohydrate_min &&
    a.carbohydrate_max === b.carbohydrate_max &&
    a.oil_min === b.oil_min &&
    a.oil_max === b.oil_max &&
    a.device__in === b.device__in
  );
}

export const useMeasurements = api("GET", "v3/measurements/")
  .inputSchema(input)
  .output(output)
  .use();

export const useMeasurement = api("GET", "v2/measurements/:uuid")
  .arg()
  .output(measurementSchema)
  .use();

export const useMeasurementGroup = api("GET", "v3/measurementGroup/:uuid")
  .arg()
  .output(measurementGroupSchema)
  .use();

export const getMeasurementGroup = api("GET", "v3/measurementGroup/:uuid")
  .arg()
  .output(measurementGroupSchema)
  .call();

export const updateAttachment = api(
  "POST",
  "v2/measurement/addAttachment/:uuid"
)
  .arg()
  .inputSchema(updateAttachmentSchema)
  .output(updateAttachmentSchema)
  .call();

export const updateGroupAttachment = api(
  "POST",
  "v2/measurementGroup/addAttachment/:uuid"
)
  .arg()
  .inputSchema(updateAttachmentSchema)
  .output(updateAttachmentSchema)
  .call();

export const deleteGroupAttachment = api(
  "DELETE",
  "v2/measurementGroup/deleteAttachment/:uuid"
)
  .arg()
  .call();

export const updateCommentSchema = yup.object({ comment: yup.string() });

export const deleteAttachment = api(
  "DELETE",
  "v2/measurement/deleteAttachment/:uuid"
)
  .arg()
  .call();

export const removeFromGroupSchema = yup.object({
  measurement_group: yup.mixed().oneOf([null]).default(null),
});

export const removeFromGroup = api("PATCH", "v1/measurements/:uuid/")
  .arg()
  .inputSchema(removeFromGroupSchema)
  .call();

export const updateComment = api("PATCH", "v1/measurements/:uuid/")
  .arg()
  .inputSchema(updateCommentSchema)
  .output(updateCommentSchema)
  .call();

export const updateGroupComment = api("PATCH", "v3/measurementGroup/:uuid")
  .arg()
  .inputSchema(updateCommentSchema)
  .output(updateCommentSchema)
  .call();

export const exportSchema = yup.object({
  measurements: yup.array(yup.string().uuid().required()).required(),
  measurement_groups: yup.array(yup.string().uuid().required()).optional(),
  send_email: yup.boolean().required(),
  format: yup.string().oneOf(["csv", "xlsx"]).required(),
  timezone: yup.string().required(),
});

export const exportMeasurements = api("POST", "v1/measurements/export/")
  .inputSchema(exportSchema)
  .download()
  .call();

export const deleteMeasurements = api(
  "POST",
  "v1/measurements/delete-multiple/"
)
  .input<string[]>()
  .call();

export const deleteMeasurement = api("DELETE", "v1/measurements/:uuid/")
  .arg()
  .call();

export const deleteMeasurementGroup = api(
  "POST",
  "v1/measurementGroup/deleteMultiple"
)
  .inputSchema(deleteMeasurementGroupsSchema)
  .call();

export const updateLocationSchema = yup.object({
  gps_latitude: yup.number().required(),
  gps_longitude: yup.number().required(),
});

export type Location = yup.Asserts<typeof updateLocationSchema>;

export const updateLocation = api("PATCH", "v1/measurements/:uuid/")
  .arg()
  .inputSchema(updateLocationSchema)
  .output(updateLocationSchema)
  .call();

export const revertLocation = api(
  "POST",
  "v1/measurements/:uuid/revert-location/"
)
  .arg()
  .output(updateLocationSchema)
  .call();

export const measurementGroupTypeOptions = [
  "Field",
  "Grain cart",
  "IBA",
  "Silo/Storage",
  "Other",
] as const;

export const addGroupSchema = yup.object({
  name: yup.string().default(""),
  comment: yup.string(),
  measurement_group_type: yup
    .string()
    .oneOf([...measurementGroupTypeOptions])
    .required(),
  calibration: yup.string().uuid().required(),
  required_number_of_measurements: yup.number().required(),
});

export const addedGroupSchema = yup.object({
  uuid: yup.string().uuid().required(),
  name: yup.string(),
  attachment: yup.string().nullable(),
  comment: yup.string(),
  measurement_group_type: yup.string().required(),
  calibration: yup.string().uuid().required(),
  required_number_of_measurements: yup.number().required(),
});

export const addMeasurementsIntoGroupSchema = yup.object({
  measurement_group_uuid: yup.string().uuid().required(),
  measurement_uuids: yup.array().of(yup.string().uuid()),
});

export const makeGroupApiCall = api("POST", "v1/measurementGroups/")
  .inputSchema(addGroupSchema)
  .output(addedGroupSchema)
  .call();

export const addMeasurementsIntoGroup = api(
  "POST",
  "v1/addMeasurementsIntoGroup/"
)
  .inputSchema(addMeasurementsIntoGroupSchema)
  .output(yup.mixed())
  .call();
