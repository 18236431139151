import React, { useContext, useEffect, useState } from "react";
import { input, updateAccount } from "api/account";
import { AccountContext } from "templates/Dashboard";
import Collapse from "components/Collapse";
import Form from "components/Form";
import FormInput from "components/FormInput";
import LanguageSelector from "components/popup/LanguageSelector";
import { Link } from "react-router-dom";
import RolesDialog from "../RolesDialog";
import { T } from "lib/language";
import { ThemeContext } from "index";
import styles from "./General.module.scss";
import useMessage from "lib/message";
import { useSession } from "lib/session";

function General() {
  // we refresh session because we want sidebar name to update
  const { refreshSession, email } = useSession();
  const sendMessage = useMessage();
  const [terminateOpen, setTerminateOpen] = useState(false);
  const [rolesDialogHidden, setRolesDialogHidden] = useState<boolean>(true);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const account = useContext(AccountContext);

  useEffect(() => {
    let roolei: string[] = [];

    if (account) {
      account.roles?.forEach((item) => {
        if (!roolei.includes(item.role.uuid)) {
          roolei.push(item.role.uuid);
        }

        if (
          item.role_choice !== null &&
          !roolei.includes(item.role_choice.uuid)
        ) {
          roolei.push(item.role_choice.uuid);
        }
      });

      setCurrentRoles(roolei);
    }
  }, [account]);

  const handleUpdateRolesClick = () => {
    setRolesDialogHidden(false);
  };

  const theme = useContext(ThemeContext);
  const getEmailLink = () => {
    return "mailto:" + theme?.links.supportEmail;
  };

  return (
    <>
      {theme?.language && theme?.language.available.length === 1 ? null : (
        <label className={styles.language}>
          <T id="account.general.language" />
          <LanguageSelector caller={"accountPage"} />
        </label>
      )}

      <Form
        className={styles.form}
        schema={input}
        onSubmit={async (data) => {
          await updateAccount(data);
          await refreshSession();
          sendMessage(<T id="account.general.success" />);
        }}
      >
        <FormInput required value={email} type="email" readOnly>
          <T id="email" />
        </FormInput>
        <FormInput
          name="first_name"
          required
          defaultValue={account?.first_name}
        >
          <T id="account.general.firstName" />
        </FormInput>
        <FormInput name="last_name" required defaultValue={account?.last_name}>
          <T id="account.general.lastName" />
        </FormInput>
        <FormInput
          name="telephone"
          type="tel"
          defaultValue={account?.telephone}
        >
          <T id="account.general.phoneNumber" />
        </FormInput>
        <button type="submit" className="cta medium">
          <T id="submit" />
        </button>
      </Form>

      <Link to="/account/general/change-password" className="ctaLink">
        <T id="account.general.changePassword" />
      </Link>
      <br />
      <br />
      <button
        type={"button"}
        className="ctaLink"
        onClick={handleUpdateRolesClick}
      >
        <T id="account.general.changeRoles" />
      </button>

      {rolesDialogHidden ? null : (
        <RolesDialog
          rolesDialogHidden={rolesDialogHidden}
          setRolesDialogHidden={setRolesDialogHidden}
          showCloseButton={true}
          currentRoles={currentRoles}
          setCurrentRoles={setCurrentRoles}
        />
      )}

      <div
        className={styles.terminateButton}
        onClick={() => setTerminateOpen((open) => !open)}
      >
        <T
          id={
            theme && theme.name === "GS"
              ? "account.general.terminateTitle"
              : "account.general.terminateTitle.others"
          }
        />
      </div>
      <Collapse open={terminateOpen} className={styles.terminate}>
        <T
          id={
            theme && theme.name === "GS"
              ? "account.general.terminate"
              : "account.general.terminate.others"
          }
          values={{
            email: <a href={getEmailLink()}>{theme?.links.supportEmail}</a>,
          }}
        />
      </Collapse>
    </>
  );
}

export default General;
