import comprehensivenessIconBlack from "images/comprehensiveness_black.svg";
import comprehensivenessIconGreen from "images/comprehensiveness_green.svg";
import comprehensivenessIconRed from "images/comprehensiveness_red.svg";
import comprehensivenessIconYellow from "images/comprehensiveness_yellow.svg";

export function ComprehensivenessIcon() {
  return <img src={comprehensivenessIconBlack} width={25} height={25} alt="" />;
}

export function ComprehensivenessIndicator(input: {
  comprehensiveness: number | null;
}) {
  const comprehensivenessIndicatorIcons = [
    comprehensivenessIconRed,
    comprehensivenessIconYellow,
    comprehensivenessIconGreen,
  ];

  if (input.comprehensiveness === null) return null;

  return (
    <img
      src={comprehensivenessIndicatorIcons[input.comprehensiveness]}
      width={25}
      height={25}
      alt=""
    />
  );
}
