import { login, loginInput } from "api/login";
import Form from "components/Form";
import FormInput from "components/FormInput";
import LanguageSelector from "components/popup/LanguageSelector";
import { Link } from "react-router-dom";
import { MobileContext } from "templates/Application";
import OtherErrors from "components/OtherErrors";
import Password from "components/Password";
import { T } from "lib/language";
import { ThemeContext } from "index";
import { create_account_url } from "config";
import styles from "./Login.module.scss";
import { useContext } from "react";
import { useSession } from "lib/session";

function Login() {
  const { logIn } = useSession();
  const theme = useContext(ThemeContext);
  const { isDesktop } = useContext(MobileContext);

  return (
    <>
      <div className={styles.titleGroup}>
        <h1>
          <T
            id={
              theme && theme.name === "GS"
                ? "login.header"
                : "login.header.others"
            }
          />
        </h1>
        <LanguageSelector caller="loginPage" />
      </div>
      <Form
        schema={loginInput}
        onSubmit={async (data) => {
          const session = await login(data);
          logIn(session);
        }}
      >
        <FormInput name="email" styleSize={!isDesktop ? "medium" : "large"}>
          <T id="email" />
        </FormInput>
        <Password styleSize={!isDesktop ? "medium" : "large"} name="password">
          <T id="password" />
        </Password>
        <OtherErrors />
        <div className={styles.ctaGroup}>
          <button type="submit" className="cta">
            <T id="login.submit" />
          </button>
          <Link className="ctaLink" to="/login/reset-password">
            <T id="resetPassword" />
          </Link>
          {theme?.name === "GS" ? (
            <a
              className="ctaLink"
              href={create_account_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <T id="createAccount" />
            </a>
          ) : null}
        </div>
      </Form>
    </>
  );
}

export default Login;
